import React from "react";
import "./Home.css";
import products from "./products"; // Ensure you have a separate file for product data
import logo from "./Assets/logoTrans.webp";
import bg from "./Assets/HomeBG.webp";
import BackToTop from "./BackToTop";

const Home = () => {
  const categories = [
    { name: "Horeca & Keukenapparatuur", icon: "bi bi-fan" },
    { name: "Meubilair & Opstellingen", icon: "bi bi-house-door" },
    { name: "Verlichting & Decoratie", icon: "bi bi-lightbulb" },
    { name: "Dranken & Bar", icon: "bi bi-cup-straw" },
    { name: "Overige & Accessoires", icon: "bi bi-tools" },
  ];

  
  return (
    <div className="container" id="home">
      <BackToTop />
      <header>
        <div className="bg">
          <div className="imgWrapper">
            <img src={bg} alt=""></img>
            <div></div>
          </div>
        </div>
        <div className="headerContent">
          <div>
            <img src={logo} alt=""></img>
            <h1>Van Baast Verhuur</h1>
            <h2>Horeca & Evenementen verhuur Brabant</h2>
          </div>
        </div>
      </header>

      <section className="intro" id="about">
    
       <div className="splitter">        
        <div>
        <div className="product-section-title">
          <i className="bi bi-file-person"></i>
          <h2 className="section-title">Over Ons</h2>
        </div>
      <p>
        Bij <strong>Van Baast Verhuur</strong> bent u aan het juiste adres voor professionele horeca- en evenementoplossingen.
        Of u nu een groot festival organiseert, een stijlvolle bruiloft plant of extra horecabenodigdheden nodig heeft voor
        uw restaurant, wij bieden een breed assortiment aan kwaliteitsmaterialen.
      </p>
      <p>
        Van glaswerk en meubilair tot complete baropstellingen en keukenapparatuur – wij hebben alles in huis om uw
        evenement of horecagelegenheid compleet te maken. 
      </p>
      <p>
        Gevestigd in <strong>Tilburg, Breda en Den Bosch</strong>, leveren wij door heel Brabant. Met onze flexibele service
        en snelle levering zorgen wij ervoor dat u zich volledig kunt richten op een geslaagd evenement.
      </p>

      <p><i className="bi bi-envelope"></i> <strong>Contact:</strong> <a href="mailto:vanbaastverhuur@gmail.com">vanbaastverhuur@gmail.com</a></p>
      </div>
      <div>
        <img src={logo} alt=""></img>
      </div>
      </div> 

    <div className="why">
      <h3>Waarom kiezen voor Van Baast Verhuur?</h3>
      <ul>
        <li><i className="bi bi-box-seam"></i> <strong>Breed assortiment:</strong> Van glaswerk en servies tot meubilair, baropstellingen en keukenapparatuur.</li>
        <li><i className="bi bi-truck"></i> <strong>Flexibel en snel:</strong> Bezorging en service door heel Brabant.</li>
        <li><i className="bi bi-shield-check"></i> <strong>Kwaliteit en betrouwbaarheid:</strong> Altijd goed onderhouden en professionele materialen.</li>
        <li><i className="bi bi-geo-alt"></i> <strong>Gevestigd in Tilburg, Breda & Den Bosch:</strong> Verhuur in heel Brabant.</li>
      </ul>
      </div>

    </section>


      <section className="product-section" id="products">
        <div className="product-section-title">
          <i className="bi bi-shop-window"></i>
          <h2 className="section-title">Onze Producten</h2>
        </div>

        <div>
          {Object.entries(
            products.reduce((acc, product) => {
              if (!acc[product.category]) acc[product.category] = [];
              acc[product.category].push(product);
              return acc;
            }, {})
          ).map(([category, categoryProducts]) => (
            <>
              <div className="catHeader">
                <i
                  className={
                    categories.find((c) => c.name === category)?.icon ||
                    "bi bi-box"
                  }
                ></i>
                <h3 className="category-title">{category}</h3>
              </div>
              <div key={category} className="product-grid">
                {categoryProducts.map((product) => (
                  <div key={product.name} className="product-card">
                    <div className="image-container">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="product-image"
                      />
                    </div>
                    <div className="product-info">
                      <h4>{product.name}</h4>
                      <div>
                        <p className="price">{product.priceValue}</p>
                        <p className="pricePeriod">{product.pricePeriod}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      </section>

      <section className="contact-section" id="contact">
        <div className="product-section-title">
          <i className="bi bi-person-lines-fill"></i>
          <h2 className="section-title">Contact</h2>
        </div>

        <p className="contact-text">
    Wilt u iets huren of heeft u vragen over ons assortiment? Neem gerust contact met ons op! Wij helpen u graag verder.
  </p>

        <p><i className="bi-envelope"></i> Mail naar: <a href="mailto:vanbaastverhuur@gmail.com">vanbaastverhuur@gmail.com</a></p>

        <p>Of stuur een bericht via het contactformulier:</p>

        <form action="https://formsubmit.co/92cdd326ae2f7d684eebaba1fb687deb" method="POST">
          <div className="form-group">
            <label>Naam:</label>
            <input type="text" name="name" required />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input type="email" name="email" required />
          </div>

          <div className="form-group">
            <label>Bericht:</label>
            <textarea name="message" rows="4" required></textarea>
          </div>

          <button type="submit" className="contact-button">
            Verstuur
          </button>
        </form>

      </section>
    </div>
  );
};

export default Home;
