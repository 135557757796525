import React from "react";
import "./Footer.css";
import logo from "./Assets/logoTrans.webp";

const Footer = () => {

    const handleScroll = (event, targetId) => {
        event.preventDefault();
        const target = document.getElementById(targetId);
        if (target) {
          const yOffset = -90; 
          const y = target.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      };
  
      
  return (
    <footer className="footer">
      <div className="footer-container">
        <img src={logo} alt="Van Baast Verhuur Logo" className="footer-logo" />

        <div className="footer-links">
          <a href="#home" onClick={(e) => handleScroll(e, "home")}>Home</a>
          <a href="#about" onClick={(e) => handleScroll(e, "about")}>Over Ons</a>
          <a href="#products" onClick={(e) => handleScroll(e, "products")}>Producten</a>
          <a href="#contact" onClick={(e) => handleScroll(e, "contact")}>Contact</a>
        </div>

        <p className="footer-text">
          &copy; {new Date().getFullYear()} Van Baast Verhuur. Alle rechten voorbehouden.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
