import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Components/Home";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  return (
    <div className="App">
     <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    <Footer />
    </div>
  );
}

export default App;
