const products = [
    { category: "Horeca & Keukenapparatuur", name: "RVS Friteuse Frituur 2 x 12,5 liter 400V", priceValue: "€110,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/fryer.jpg` },
    { category: "Horeca & Keukenapparatuur", name: "Inductie plaat", priceValue: "€20,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/induction.jpg` },
    { category: "Horeca & Keukenapparatuur", name: "Black Bastard BBQ Large", priceValue: "€80,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image16.jpeg` },
    { category: "Horeca & Keukenapparatuur", name: "Black Bastard BBQ Compact", priceValue: "€60,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image17.jpeg` },
    { category: "Horeca & Keukenapparatuur", name: "Slush puppy machine Polar 2x 12 L", priceValue: "€105,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image15.jpeg`  },
    { category: "Horeca & Keukenapparatuur", name: "Vriezer 600L Combisteel wielen", priceValue: "€85,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image9.jpeg`  },
    { category: "Horeca & Keukenapparatuur", name: "Koelkast 600L", priceValue: "€85,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image9.jpeg` },
    { category: "Horeca & Keukenapparatuur", name: "Werktafel RVS 2M", priceValue: "€42,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image7.jpeg` },
    { category: "Horeca & Keukenapparatuur", name: "Chafing dish basic 1/1 GN incl. 4x brandbasta", priceValue: "€18,50", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image6.jpeg` },
    { category: "Horeca & Keukenapparatuur", name: "Hotpot zwart 8-10L", priceValue: "€24,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/hotpot.jpg` },
    { category: "Horeca & Keukenapparatuur", name: "Warmhoudbrug", priceValue: "€50,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/heatbridge.jpg` },
    { category: "Meubilair & Opstellingen", name: "Barkruk", priceValue: "€6,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/barstool.jpg` },
    { category: "Meubilair & Opstellingen", name: "Partytent - Easy up - 3x3m", priceValue: "€140,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image4.png` },
    { category: "Meubilair & Opstellingen", name: "Partytent - Easy up - 4x4m", priceValue: "€195,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image2.jpeg` },
    { category: "Meubilair & Opstellingen", name: "Mobiele bar 2 taps", priceValue: "€160,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/bar.webp` },
    { category: "Meubilair & Opstellingen", name: "Statafel", priceValue: "€15,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/table.webp` },   
    { category: "Meubilair & Opstellingen", name: "Picknicktafel", priceValue: "€80,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/picknick.jpg` },
    { category: "Meubilair & Opstellingen", name: "Parasol 4x4m zwart", priceValue: "€55,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image3.jpeg` },
    { category: "Verlichting & Decoratie", name: "Kunst palm", priceValue: "€35,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/palm.webp` },
    { category: "Verlichting & Decoratie", name: "Prikkabel verlichting 35M", priceValue: "€35,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image1.jpeg` },
    { category: "Verlichting & Decoratie", name: "Prikkabel verlichting 10M", priceValue: "€18,-", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/image1.jpeg` },
    { category: "Dranken & Bar", name: "50L Heineken fust", priceValue: "€175,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/heineken.jpeg` },
    { category: "Dranken & Bar", name: "20L Heineken fust", priceValue: "€80,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/heineken20.jpg` },
    { category: "Dranken & Bar", name: "20L Birra Moretti fust", priceValue: "€90,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/morreti.webp` },
    { category: "Dranken & Bar", name: "Limoncello 1L", priceValue: "€18,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/limoncello.png` },
    { category: "Dranken & Bar", name: "Arancello 1L", priceValue: "€18,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/valenti.png` },
    { category: "Dranken & Bar", name: "Hennessy 0,7L", priceValue: "€34,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/hennesey.jpg` },
    { category: "Dranken & Bar", name: "Gin 1L", priceValue: "€18,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/gin.jpg` },
    { category: "Dranken & Bar", name: "Sambuca 0,7L", priceValue: "€18,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/sambuca.jpg` },
    { category: "Dranken & Bar", name: "Vodka 1L", priceValue: "€18,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/vodka.jpg` },
    { category: "Overige & Accessoires", name: "Houtskool 15kg", priceValue: "€36,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/kool.webp` },
    { category: "Overige & Accessoires", name: "Aanmaakpasta 8st", priceValue: "€9,50", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/pasta.jpg` },
    { category: "Overige & Accessoires", name: "Ijsemmer", priceValue: "€5,-", pricePeriod: "", image: `${process.env.PUBLIC_URL}/Assets/icebucket.jpg` },    
    { category: "Overige & Accessoires", name: "Brandblusser schuim 6L", priceValue: "€18,50", pricePeriod: "per dag", image: `${process.env.PUBLIC_URL}/Assets/foam.jpg` },
   
];

export default products;
