import React, { useState, useEffect, useRef } from "react";
import "./Nav.css";
import logo from "./Assets/logoTrans.webp";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navRef = useRef(null);

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuOpen]);

  // Detect scrolling and shrink navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToSection = (event, targetId) => {
    setMenuOpen(false); // Close menu when clicking a nav link
    event.preventDefault();
    const target = document.getElementById(targetId);
    if (target) {
      const yOffset = -90;
      const y = target.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`} ref={navRef}>
      <div className="nav-container">
        <img src={logo} alt="Logo" className={`logo ${isScrolled ? "small" : ""}`} />

        {/* Mobile Menu Toggle */}
        <div className={`menu-toggle`} onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </div>

        <ul className={`nav-links ${menuOpen ? "open" : ""} ${isScrolled ? "scrolledMenu" : ""}`}>
          <li><a href="#home" onClick={(e) => handleScrollToSection(e, "home")}>Home</a></li>
          <li><a href="#about" onClick={(e) => handleScrollToSection(e, "about")}>Over Ons</a></li>
          <li><a href="#products" onClick={(e) => handleScrollToSection(e, "products")}>Producten</a></li>
          <li><a href="#contact" onClick={(e) => handleScrollToSection(e, "contact")}>Contact</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
